import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/react";
import { motion } from "framer-motion";

import { ThemeProvider, useTheme } from "./components/ThemeContext";
import { lightTheme, darkTheme, createCssVariables } from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./components/Home";
import RSVP from "./components/RSVP";
import Details from "./components/Details";
import Registry from "./components/Registry";

const AppContainer = styled.div`
  font-family: "Montserrat", "Arial", sans-serif;
  color: var(--color-text);
  height: 100vh;
`;

const FixedHeader = styled.header`
  padding: 1rem 0;
  position: sticky;
  top: 0;
  z-index: 1001;
  width: 100%;
  background-color: var(--color-background-blurred);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); // For Safari support
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.035);
`;

const ContentContainer = styled.div`
  max-width: 1200px; // Adjust this value as needed
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: var(--color-margin-border);
    z-index: 1000;
  }

  &::before {
    left: calc((100% - 1200px) / 2);
  }

  &::after {
    right: calc((100% - 1200px) / 2);
  }

  @media (max-width: 1200px) {
    &::before,
    &::after {
      display: none;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: center; // Changed from space-between to center
  flex-wrap: wrap;
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: var(--color-text);
  font-family: "Montserrat", "Arial", sans-serif;
  padding: 0.5rem 0.75rem; // Reduced horizontal padding
  margin: 0 0.25rem; // Added small horizontal margin
  border-radius: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-accent-light);
  }

  &.active {
    background-color: var(--color-primary);
    color: var(--color-text-nav);
  }

  @media (max-width: 768px) {
    margin: 0.25rem; // Reduced margin for mobile
  }
`;

const Main = styled.main`
  padding: 0 3rem 0;

  @media (max-width: 768px) {
    padding: 0 1rem 0; // Reduced margin for mobile
  }
`;

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  in: { opacity: 1, y: 0 },
  out: { opacity: 0, y: -20 },
};

const pageTransition = {
  type: "tween",
  ease: "anticipate",
  duration: 1,
};

function App() {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <Router>
      <ScrollToTop />
      <Global
        styles={css`
          ${createCssVariables(theme)}
          body {
            background-color: var(--color-background);
            color: var(--color-text);
          }
        `}
      />
      <AppContainer>
        <FixedHeader>
          <Nav>
            <StyledNavLink to="/">Home</StyledNavLink>
            <StyledNavLink to="/details">Details</StyledNavLink>
            <StyledNavLink to="/rsvp">RSVP</StyledNavLink>
            <StyledNavLink to="/registry">Registry</StyledNavLink>
          </Nav>
        </FixedHeader>
        <ContentContainer>
          <Main>
            <Routes>
              <Route
                path="/"
                element={
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Home />
                  </motion.div>
                }
              />
              <Route
                path="/rsvp"
                element={
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <RSVP />
                  </motion.div>
                }
              />
              <Route
                path="/details"
                element={
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Details />
                  </motion.div>
                }
              />
              <Route
                path="/registry"
                element={
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <Registry />
                  </motion.div>
                }
              />
            </Routes>
          </Main>
        </ContentContainer>
      </AppContainer>
    </Router>
  );
}

function AppWithTheme() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
}

export default AppWithTheme;
