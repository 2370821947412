import React from "react";
import styled from "@emotion/styled";

export const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin: 2rem 0;
`;

const ImageWrapper = styled.div`
  flex: 1 1 200px; // Grow, shrink, and base width
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    max-width: calc(50% - 0.5rem); // 2 images per row on medium screens
  }

  @media (max-width: 480px) {
    max-width: 100%; // 1 image per row on small screens
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const ImageGallery = ({ images }) => {
  return (
    <GalleryContainer>
      {images.map((image, index) => (
        <ImageWrapper key={index}>
          <StyledImage
            src={image.src}
            alt={image.alt || `Image ${index + 1}`}
          />
        </ImageWrapper>
      ))}
    </GalleryContainer>
  );
};
