import React from "react";
import styled from "@emotion/styled";
import { Title, Subtitle, Paragraph } from "./Text";
import { ImageGallery } from "./Images";

const DetailsContainer = styled.div`
  text-align: center;
`;

const QuestionList = styled.dl`
  text-align: left;
`;

const Question = styled.dt`
  font-weight: bold;
  margin-top: 1rem;
  line-height: 3rem;
`;

function Details() {
  const weddingDate = "September 20, 2025";
  const venue = "The Meadowlands at the DCA"
  const galleryImages = [
    { src: "/images/dca-table.jpg", alt: "DCA House" },
    { src: "/images/dca-table-02.jpg", alt: "DCA House Table Setting" },
    { src: "/images/dca-dress.jpg", alt: "DCA House Indoors" },
  ];
  return (
    <>
      <DetailsContainer>
        <Title>Details</Title>
        <Subtitle>Venue</Subtitle>
        <ImageGallery images={galleryImages}></ImageGallery>
        <Paragraph>
          Our wedding will be at the beatiful Meadowlands at the DCA.
          The ceremony will take place in the lush outdoors garden area, with the reception
          to follow in their indoor reception hall. 
        </Paragraph>
        <Subtitle>Q&A</Subtitle>
        <QuestionList>
          <Question>When is the wedding?</Question>
          <dd>Our wedding will be held on {weddingDate}!</dd>
          <Question>Where is the venue?</Question>
          <dd>
            The ceremony and reception will take place at {venue}. The address is:
            <br />
            <br />
            <strong>274 Middlesex Rd, Darien, CT 06820</strong>
            <br />
            <br />
            Visit their site for more information: <a href="https://dariendca.org/meadowlands/">Meadowlands at the DCA</a>
          </dd>
        </QuestionList>
        <br />
        <Paragraph>More details to be added soon!</Paragraph>
      </DetailsContainer>
    </>
  );
}

export default Details;
